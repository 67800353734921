import React from 'react'
import "./aboutMainBanner.css"
import SustainImage from "../../images/aboutMainBanner.png"

export default function AboutMainBanner() {
  return (
    <div>
      <div>
        <div style={{ position: "relative" }}>
          <img className="aboutMain-home-image" alt="" src={SustainImage} />
          <div className="aboutMain-image-text-box">
            <div className="aboutMain-image-background-text">
              <h1 className="aboutMain-image-topic">
                About Us
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
