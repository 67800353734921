import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import AboutMainBanner from "../templates/AboutUs/aboutMainBanner"
import AboutCIC from "../templates/AboutUs/aboutCIC"
import AboutChairman from "../templates/AboutUs/aboutChairman"
import AboutBetterFuture from "../templates/AboutUs/aboutBetterFuture"
import AboutValues from "../templates/AboutUs/aboutValues"
import AboutHistory from "../templates/AboutUs/aboutHistory"
import AboutBrandStory from "../templates/AboutUs/aboutBrandStory"
import AboutDirectors from "../templates/AboutUs/aboutDirectors"

export default function AboutUs() {
  return (
    <div>
      <Header />
      <AboutMainBanner />
      <AboutCIC />
      <AboutChairman />
      <AboutBetterFuture />
      <AboutValues />
      <AboutHistory />
      <AboutBrandStory />
      <AboutDirectors />
      <Footer />
    </div>
  )
}
