import React from 'react'
import "./aboutHistory.css"
import AboutHistoryImage from "../../images/placeholder-image2@2x.png"

export default function AboutHistory() {
  return (
    <div>
      <div className="ourHistory-timeline-14">
            <div className="ourHistory-content21">
              <div className="ourHistory-timeline-progress">
                <div className="ourHistory-content22">
                  <div className="ourHistory-date">1964</div>
                  <div className="ourHistory-progress-container">
                    <div className="ourHistory-divider1" />
                    <div className="ourHistory-circle" />
                    <div className="ourHistory-divider1" />
                  </div>
                </div>
                <div className="ourHistory-content23">
                  <div className="ourHistory-date">1968 – 1974</div>
                  <div className="ourHistory-progress-container">
                    <div className="ourHistory-divider3" />
                    <div className="ourHistory-circle1" />
                    <div className="ourHistory-divider3" />
                  </div>
                </div>
                <div className="ourHistory-content23">
                  <div className="ourHistory-date">1990s</div>
                  <div className="ourHistory-progress-container">
                    <div className="ourHistory-divider3" />
                    <div className="ourHistory-circle1" />
                    <div className="ourHistory-divider3" />
                  </div>
                </div>
                <div className="ourHistory-content23">
                  <div className="ourHistory-date">2000s</div>
                  <div className="ourHistory-progress-container">
                    <div className="ourHistory-divider3" />
                    <div className="ourHistory-circle1" />
                    <div className="ourHistory-divider3" />
                  </div>
                </div>
                <div className="ourHistory-content23">
                  <div className="ourHistory-date">2010</div>
                  <div className="ourHistory-progress-container">
                    <div className="ourHistory-divider3" />
                    <div className="ourHistory-circle1" />
                    <div className="ourHistory-divider3" />
                  </div>
                </div>
              </div>
              <div className="ourHistory-container5">
                <div className="ourHistory-content-top3">
                  <div className="ourHistory-content27">
                    <div className="ourHistory-heading">Our History</div>
                    <div className="ourHistory-titles">
                      <div className="ourHistory-heading5">1964</div>
                      <div className="ourHistory-quote">
                        Our story begins in 1964, with Chemical Industries
                        (Colombo) Limited, now known as CIC Holdings being
                        incorporated, and listed in the Colombo Brokers
                        Association.
                      </div>
                    </div>
                  </div>
                </div>
                <img
                  className="ourHistory-placeholder-image-icon1"
                  alt="loading"
                  src={AboutHistoryImage}
                />
              </div>
            </div>
          </div>
    </div>
  )
}
