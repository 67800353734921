import React from 'react'
import "./aboutDirectors.css"
import DirectorIcon1 from "../../images/placeholder-image3@2x.png"
import DirectorIcon2 from "../../images/placeholder-image4@2x.png"
import Icon1 from "../../images/placeholder-image5@2x.png"
import Icon2 from "../../images/vector-17.svg"
import Icon3 from "../../images/vector-6.svg"
import DirectorIcon3 from "../../images/placeholder-image6@2x.png"
import DirectorIcon4 from "../../images/placeholder-image7@2x.png"
import DirectorIcon5 from "../../images/placeholder-image8@2x.png"
import DirectorIcon6 from "../../images/placeholder-image9@2x.png"
import DirectorIcon7 from "../../images/placeholder-image10@2x.png"
import DirectorIcon8 from "../../images/placeholder-image11@2x.png"

export default function AboutDirectors() {
  return (
    <div>
      <div className="dir-team-16">
            <div className="dir-section-title7">
              <div className="dir-content6">
                <div className="dir-heading">Board of Directors</div>
              </div>
            </div>
            <div className="dir-content30">
              <div className="dir-container6">
                <div className="dir-row1">
                  <div className="dir-card3">
                    <img
                      className="dir-placeholder-image-icon3"
                      alt=""
                      src={DirectorIcon1}
                    />
                    <div className="dir-column12">
                      <div className="dir-content31">
                        <div className="dir-title">
                          <div className="dir-date">S H Amarasekera</div>
                          <div className="dir-job-title">
                            Independent Non-Executive Director/Chairman
                          </div>
                        </div>
                      </div>
                      <div className="dir-button28">
                        <div className="dir-button-child" />
                        <img
                          className="dir-button-item"
                          alt=""
                          src={Icon2}
                        />
                        <div className="dir-read-more">Read More</div>
                        <img
                          className="dir-button-inner"
                          alt=""
                          src={Icon3}
                        />
                      </div>
                      <div className="dir-social-icons">
                        <img className="dir-icon-linkedin" alt="" />
                        <img className="dir-icon-linkedin" alt="" />
                        <img className="dir-icon-linkedin" alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="dir-card3">
                    <img
                      className="dir-placeholder-image-icon3"
                      alt=""
                      src={DirectorIcon2}
                    />
                    <div className="dir-column12">
                      <div className="dir-content31">
                        <div className="dir-title">
                          <div className="dir-date">R S Captain</div>
                          <div className="dir-job-title">
                            Non Independent Non-Executive Director
                          </div>
                        </div>
                      </div>
                      <div className="dir-button28">
                        <div className="dir-button-child" />
                        <img
                          className="dir-button-item"
                          alt=""
                          src={Icon2}
                        />
                        <div className="dir-read-more">Read More</div>
                        <img
                          className="dir-button-inner"
                          alt=""
                          src={Icon3}
                        />
                      </div>
                      <div className="dir-social-icons">
                        <img className="dir-icon-linkedin" alt="" />
                        <img className="dir-icon-linkedin" alt="" />
                        <img className="dir-icon-linkedin" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="dir-row1">
                  <div className="dir-card3">
                    <img
                      className="dir-placeholder-image-icon3"
                      alt=""
                      src={DirectorIcon3}
                    />
                    <div className="dir-column12">
                      <div className="dir-content31">
                        <div className="dir-title">
                          <div className="dir-date">S M Enderby</div>
                          <div className="dir-job-title">
                            Independent Non-Executive Director
                          </div>
                        </div>
                      </div>
                      <div className="dir-button28">
                        <div className="dir-button-child" />
                        <img
                          className="dir-button-item"
                          alt=""
                          src={Icon2}
                        />
                        <div className="dir-read-more">Read More</div>
                        <img
                          className="dir-button-inner"
                          alt=""
                          src={Icon3}
                        />
                      </div>
                      <div className="dir-social-icons">
                        <img className="dir-icon-linkedin" alt="" />
                        <img className="dir-icon-linkedin" alt="" />
                        <img className="dir-icon-linkedin" alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="dir-card3">
                    <img
                      className="dir-placeholder-image-icon3"
                      alt=""
                      src={DirectorIcon4}
                    />
                    <div className="dir-column12">
                      <div className="dir-content31">
                        <div className="dir-title">
                          <div className="dir-date">M P Jayawardena</div>
                          <div className="dir-job-title">
                            Non-Independent Non-Executive Director
                          </div>
                        </div>
                      </div>
                      <div className="dir-button28">
                        <div className="dir-button-child" />
                        <img
                          className="dir-button-item"
                          alt=""
                          src={Icon2}
                        />
                        <div className="dir-read-more">Read More</div>
                        <img
                          className="dir-button-inner"
                          alt=""
                          src={Icon3}
                        />
                      </div>
                      <div className="dir-social-icons">
                        <img className="dir-icon-linkedin" alt="" />
                        <img className="dir-icon-linkedin" alt="" />
                        <img className="dir-icon-linkedin" alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="dir-card5">
                    <img
                      className="dir-placeholder-image-icon3"
                      alt=""
                      src={Icon1}
                    />
                  </div>
                </div>
                <div className="dir-row1">
                  <div className="dir-card3">
                    <img
                      className="dir-placeholder-image-icon3"
                      alt=""
                      src={DirectorIcon5}
                    />
                    <div className="dir-column12">
                      <div className="dir-content31">
                        <div className="dir-title">
                          <div className="dir-date">P R Saldin</div>
                          <div className="dir-job-title">
                            Non-Independent Non-Executive Director
                          </div>
                        </div>
                      </div>
                      <div className="dir-button28">
                        <div className="dir-button-child" />
                        <img
                          className="dir-button-item"
                          alt=""
                          src={Icon2}
                        />
                        <div className="dir-read-more">Read More</div>
                        <img
                          className="dir-button-inner"
                          alt=""
                          src={Icon3}
                        />
                      </div>
                      <div className="dir-social-icons">
                        <img className="dir-icon-linkedin" alt="" />
                        <img className="dir-icon-linkedin" alt="" />
                        <img className="dir-icon-linkedin" alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="dir-card3">
                    <img
                      className="dir-placeholder-image-icon3"
                      alt=""
                      src={DirectorIcon6}
                    />
                    <div className="dir-column12">
                      <div className="dir-content31">
                        <div className="dir-title">
                          <div className="dir-name7">Sujeewa Mudalige</div>
                          <div className="dir-job-title">
                            Non-Independent Non-Executive Director
                          </div>
                        </div>
                      </div>
                      <div className="dir-button28">
                        <div className="dir-button-child" />
                        <img
                          className="dir-button-item"
                          alt=""
                          src={Icon2}
                        />
                        <div className="dir-read-more">Read More</div>
                        <img
                          className="dir-button-inner"
                          alt=""
                          src={Icon3}
                        />
                      </div>
                      <div className="dir-social-icons">
                        <img className="dir-icon-linkedin" alt="" />
                        <img className="dir-icon-linkedin" alt="" />
                        <img className="dir-icon-linkedin" alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="dir-card5">
                    <img
                      className="dir-placeholder-image-icon3"
                      alt=""
                      src={Icon1}
                    />
                  </div>
                </div>
                <div className="dir-row1">
                  <div className="dir-card3">
                    <img
                      className="dir-placeholder-image-icon3"
                      alt=""
                      src={DirectorIcon7}
                    />
                    <div className="dir-column12">
                      <div className="dir-content31">
                        <div className="dir-title">
                          <div className="dir-date">J R Gunaratne</div>
                          <div className="dir-job-title">
                            Independent Non-Executive Director
                          </div>
                        </div>
                      </div>
                      <div className="dir-button28">
                        <div className="dir-button-child" />
                        <img
                          className="dir-button-item"
                          alt=""
                          src={Icon2}
                        />
                        <div className="dir-read-more">Read More</div>
                        <img
                          className="dir-button-inner"
                          alt=""
                          src={Icon3}
                        />
                      </div>
                      <div className="dir-social-icons">
                        <img className="dir-icon-linkedin" alt="" />
                        <img className="dir-icon-linkedin" alt="" />
                        <img className="dir-icon-linkedin" alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="dir-card3">
                    <img
                      className="dir-placeholder-image-icon3"
                      alt=""
                      src={DirectorIcon8}
                    />
                    <div className="dir-column12">
                      <div className="dir-content31">
                        <div className="dir-title">
                          <div className="dir-date">K D Senewiratne</div>
                          <div className="dir-job-title">
                            Independent, Non-Executive Director
                          </div>
                        </div>
                      </div>
                      <div className="dir-button28">
                        <div className="dir-button-child" />
                        <img
                          className="dir-button-item"
                          alt=""
                          src={Icon2}
                        />
                        <div className="dir-read-more">Read More</div>
                        <img
                          className="dir-button-inner"
                          alt=""
                          src={Icon3}
                        />
                      </div>
                      <div className="dir-social-icons">
                        <img className="dir-icon-linkedin" alt="" />
                        <img className="dir-icon-linkedin" alt="" />
                        <img className="dir-icon-linkedin" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
    </div>
  )
}
