import React from 'react'
import "./aboutCIC.css"

export default function AboutCIC() {
  return (
    <div>
      <div className="aboutCIC-layout-42">
            <div className="aboutCIC-content2">
              <div className="aboutCIC-column2">
                <div className="aboutCIC-heading">About CIC</div>
              </div>
              <div className="aboutCIC-column3">
                <div className="aboutCIC-text">
                  Chemical Industries (Colombo) Limited (initially a part of
                  Imperial Chemical Industries-UK), was incorporated in 1964 as
                  a supplier of high-quality chemical products for the local
                  market. We are now known as CIC Holdings PLC, one of Sri
                  Lanka’s leading conglomerates, and over the years have become
                  a household name through our many ventures.
                </div>
              </div>
            </div>
          </div>
    </div>
  )
}
